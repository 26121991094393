import { FC, Fragment, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  FailPage,
  GroupRequestPage,
  MainMOPage,
  NotFoundPage,
  PreviewPage,
  StatusXXPage,
} from '@bepro-travel/fe.shared';
import { MainContext } from '../contexts/MainContext';
import Login from '../pages/Login';
import { HotelConfigContext } from '@bepro-travel/fe.hotel';
import { HotelPages } from './HotelPages';
import MainPage from '../pages/MainPage';
import MainTransferPage from './MainTransferPage';
import {
  BookTransferPage,
  ResultTransferPage,
  SuccessTransferPage,
  TransferPaymentPage,
} from '@bepro-travel/fe.transfer';

interface RouterConfigProps {
  isEnigma: boolean;
}

const RouterConfig: FC<RouterConfigProps> = ({ isEnigma }) => {
  const { pathname } = useLocation();
  const { user } = useContext(MainContext);
  const navigate = useNavigate();
  const hcc = useContext(HotelConfigContext);
  const hide404 =
    [
      '/',
      '/hotels',
      '/hotel/book',
      '/hotel/payment',
      '/hotel/success',
      '/login',
      '/my-orders',
      '/group',
      '/preview',
      '/status-xx',
      '/fail',
      '/transfers',
      '/transfers/results',
      '/transfer/book',
      '/transfer/payment',
      '/transfer/success',
    ].some((path) => pathname === path) ||
    /^\/hotels\d*(\/results)?$/.test(pathname) ||
    /^\/hotel\d*\/(book|payment|success|details)$/.test(pathname);

  useEffect(() => {
    if ((!user || !user.isAuth) && pathname !== '/login') {
      navigate('/login');
    }
  }, [pathname]); // eslint-disable-line

  return (
    <Fragment>
      <HotelPages isEnigma={isEnigma} />
      <HotelConfigContext.Provider value={hcc}>
        <div
          style={{
            display: pathname === `/` ? 'block' : 'none',
          }}
        >
          <MainPage isEnigma={isEnigma} />
        </div>
      </HotelConfigContext.Provider>
      <HotelConfigContext.Provider value={{ ...hcc, hotelIndex: 2 }}>
        <HotelPages isEnigma={isEnigma} hotelIndex={2} />
      </HotelConfigContext.Provider>
      <Login isEnigma={isEnigma} />
      <div style={{ display: pathname === '/my-orders' ? 'block' : 'none' }}>
        <MainMOPage />
      </div>
      <div style={{ display: pathname === '/group' ? 'block' : 'none' }}>
        <GroupRequestPage />
      </div>
      <div style={{ display: pathname === '/preview' ? 'block' : 'none' }}>
        <PreviewPage />
      </div>
      <div style={{ display: pathname === '/status-xx' ? 'block' : 'none' }}>
        <StatusXXPage />
      </div>
      <div style={{ display: pathname === '/fail' ? 'block' : 'none' }}>
        <FailPage />
      </div>
      <div
        style={{
          display: hide404 ? 'none' : 'block',
        }}
      >
        <NotFoundPage />
      </div>

      <div style={{ display: pathname === '/transfers' ? 'block' : 'none' }}>
        <MainTransferPage />
      </div>

      <div style={{ display: pathname === '/transfers/results' ? 'block' : 'none' }}>
        <ResultTransferPage />
      </div>

      <div style={{ display: pathname === '/transfer/book' ? 'block' : 'none' }}>
        <BookTransferPage />
      </div>

      <div style={{ display: pathname === '/transfer/payment' ? 'block' : 'none' }}>
        <TransferPaymentPage />
      </div>

      <div style={{ display: pathname === '/transfer/success' ? 'block' : 'none' }}>
        <SuccessTransferPage />
      </div>
    </Fragment>
  );
};

export default RouterConfig;
