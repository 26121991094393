import { FC, useContext, useEffect, useState } from 'react';
import { getRandomImage, getWorkingHours } from '../services/config';
import { SearchHotel } from '@bepro-travel/fe.hotel';
import { cdnUrl } from '../constants/generic';
import { WorkingHoursData } from '../ts/interfaces/Generic';
import ContactCenter from '../components/ContactCenter';
import { useLocation } from 'react-router-dom';
import { MainContext } from '../contexts/MainContext';

interface MainCustomageProps {
  isEnigma: boolean;
}
const MainCustomage: FC<MainCustomageProps> = ({ isEnigma }) => {
  const [workingHours, setWorkingHours] = useState<WorkingHoursData>();
  const location = useLocation();
  const [bgImage, setBgImage] = useState<{ backgroundImage: string }>();
  const { user } = useContext(MainContext);

  useEffect(() => {
    (async () => {
      if (location.pathname !== '/' || !user?.isAuth) {
        return;
      }

      if (!isEnigma) {
        const response = await getRandomImage();
        setBgImage({ backgroundImage: `url(${cdnUrl + response?.imageUrl})` });
      }
      const data = await getWorkingHours();
      try {
        const workingHours = JSON.parse(data.officeInformation);
        setWorkingHours(workingHours);
      } catch (e) {}
    })();
  }, [location.pathname]); // eslint-disable-line

  return (
    <div className="display-flex">
      <section className="main-page hotels" style={bgImage}>
        {workingHours && <ContactCenter workingHours={workingHours} />}
        <SearchHotel loadingSearch={false} onClickStop={() => {}} />
      </section>
    </div>
  );
};

export default MainCustomage;
